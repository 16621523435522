import { VideoTrack } from 'twilio-video';

export type RenderDimensionValue =
  | 'vga'
  | '720p';

export interface RenderDimension {
  label: string;
  value: RenderDimensionValue;
  resolution?: VideoTrack.Dimensions;
}

export const RenderDimensions: RenderDimension[] = [
  {
    label: 'VGA (640 x 480)',
    value: 'vga',
    resolution: { width: 640, height: 480 },
  },
  {
    label: 'HD 720P (1280 x 720)',
    value: '720p',
    resolution: { width: 1280, height: 720 },
  },
];

export function getResolution(value?: RenderDimensionValue) {
  if (typeof value === 'undefined') {
    return undefined;
  }

  return RenderDimensions.find(item => item.value === value)?.resolution;
}
