import { ConnectOptions } from 'twilio-video';
import { removeUndefineds } from '..';
import { Settings } from '../../state/settings/settingsReducer';

export default function generateConnectionOptions(settings: Settings) {
  // See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions: ConnectOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
	video: { height: Number(settings.videoHeight), frameRate: 24, width: Number(settings.videoWidth)},
    audio: true,
    // Comment this line if you are playing music.
    maxAudioBitrate: Number(settings.maxAudioBitrate)
  };

  // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
  // when no value is passed for an option. It will throw an error when 'undefined' is passed.
  return removeUndefineds(connectionOptions);
}
