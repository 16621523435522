import React, { useCallback } from 'react';
import {
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { inputLabels, Settings } from '../../../state/settings/settingsReducer';
import { RenderDimensions } from '../../../state/settings/renderDimensions';
import { useAppState } from '../../../state';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import VideoInputList from '../DeviceSelector/VideoInputList/VideoInputList';

const useStyles = makeStyles({
  formControl: {
    display: 'block',
    margin: '1.5em 0',
    '&:first-child': {
      margin: '0 0 1.5em 0',
    },
  },
  label: {
    width: '133%', // Labels have scale(0.75) applied to them, so this effectively makes the width 100%
  },
});

const withDefault = (val?: string) => (typeof val === 'undefined' ? 'default' : val);

const RenderDimensionItems = RenderDimensions.map(({ label, value }) => (
  <MenuItem value={value} key={value}>
    {label}
  </MenuItem>
));

export default function ConnectionOptions({ className, hidden }: { className?: string; hidden?: boolean }) {
  const classes = useStyles();
  const { settings, dispatchSetting } = useAppState();
  const roomState = useRoomState();
  const isDisabled = roomState !== 'disconnected';

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
      dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
    },
    [dispatchSetting]
  );

  return (
    <DialogContent className={className} hidden={hidden}>
		<Grid container spacing={2}>		
			<Grid item sm={12} xs={12}>
				<VideoInputList />
				<FormControl fullWidth className={classes.formControl}>
					<InputLabel id={inputLabels.sendDimension} className={classes.label}>
					  Send Resolution (maximum)
					</InputLabel>
					<Select
					  fullWidth
					  disabled={isDisabled}
					  name={inputLabels.sendDimension}
					  label={inputLabels.sendDimension}
					  value={withDefault(settings.sendDimension)}
					  onChange={handleChange}
					>
					  {RenderDimensionItems}
					</Select>
				</FormControl>
				<FormControl fullWidth className={classes.formControl}>
					<InputLabel id={inputLabels.receiveDimension} className={classes.label}>
					  Receive Resolution (maximum)
					</InputLabel>
					<Select
					  fullWidth
					  disabled={isDisabled}
					  name={inputLabels.receiveDimension}
					  label={inputLabels.receiveDimension}
					  value={withDefault(settings.receiveDimension)}
					  onChange={handleChange}
					>
					  {RenderDimensionItems}
					</Select>
				</FormControl>
			</Grid>			
      </Grid>
    </DialogContent>
  );
}
