import React from 'react';
import { FormControl, MenuItem, Typography, Select, InputLabel } from '@material-ui/core';
import { useAppState } from '../../../../state';
import { useAudioOutputDevices } from '../deviceHooks/deviceHooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formControl: {
    display: 'block',
    margin: '1.5em 0',
    '&:first-child': {
      margin: '0 0 1.5em 0',
    },
  },
  label: {
    width: '133%', // Labels have scale(0.75) applied to them, so this effectively makes the width 100%
  },
});

export default function AudioOutputList() {
	const classes = useStyles();
	const audioOutputDevices = useAudioOutputDevices();
	const { activeSinkId, setActiveSinkId } = useAppState();

	return (
		<div className="inputSelect">
		  {audioOutputDevices.length > 0 ? (
			<FormControl fullWidth className={classes.formControl}>
			  <InputLabel className={classes.label}>Speakers</InputLabel>
			  <Select fullWidth onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId}>
				{audioOutputDevices.map(device => (
				  <MenuItem value={device.deviceId} key={device.deviceId}>
					{device.label}
				  </MenuItem>
				))}
			  </Select>
			</FormControl>
		  ) : (
			<>
			  <Typography variant="h6">Speakers</Typography>
			  <Typography>{'System Default Audio Output'}</Typography>
			</>
		  )}
		</div>
	);
}
